import { axiosInstance } from '../../utils/api/api-utils';

export default class LeaderboardApi {
    static getCurrentLeaderboard(token: string | undefined): Promise<any> {
        return axiosInstance
            .get('/api/leaderboard', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res.data;
            });
    }

    static getLastMonthLeaderboard(token: string | undefined): Promise<any> {
        return axiosInstance
            .get('/api/leaderboard/lastMonth', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res.data;
            });
    }
}
