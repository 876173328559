import { Box } from '@mui/system';
import { FC, useContext, useEffect, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Tooltip,
} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment-timezone';
import snackbarUtils from '../../utils/snackbar/snackbar-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faX } from '@fortawesome/free-solid-svg-icons';
import { ContractStatus } from '../../types/contract';
import { Regions } from '../../types/region';
import { AppContext } from '../../hooks/context';
import UserSelection from './users-selection';
import { Role } from '../../types/role';

interface FilterToolbarProps {
    fromDate: moment.Moment;
    setFromDate: (date: moment.Moment) => void;
    toDate: moment.Moment;
    setToDate: (date: moment.Moment) => void;
    showAll: boolean;
    setShowAll: (v: boolean) => void;
    showMy: boolean;
    setShowMy: (v: boolean) => void;
    statusFilter: string[];
    setStatusFilter: (v: string[]) => void;
    searchText: string;
    setSearchText: (v: string) => void;
    regionFilter: string;
    setRegionFilter: (v: string) => void;
    userFilter: { username: string; id: string }[];
    setUserFilter: (userId: { username: string; id: string }[]) => void;
}

const FilterToolbar: FC<FilterToolbarProps> = (props: FilterToolbarProps) => {
    const {
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        showAll,
        setShowAll,
        statusFilter,
        setStatusFilter,
        searchText,
        setSearchText,
        regionFilter,
        setRegionFilter,
        showMy,
        setShowMy,
        userFilter,
        setUserFilter,
    } = props;
    const context = useContext(AppContext);
    const [transientSearchText, setTransientSearchText] = useState(searchText);

    const [includeInactiveUsers, setIncludeInactiveUsers] = useState(false);

    const [regionFilterOptions, setRegionFilterOptions] = useState<
        JSX.Element[]
    >([]);

    useEffect(() => {
        if (context.isAdmin) {
            const options: JSX.Element[] = [];
            options.push(
                <MenuItem key="DefaultRegion" value="">
                    <ListItemText primary="Region" />
                </MenuItem>,
            );
            let r: string[] = [];
            if (context.isSuperadmin) {
                r = Object.keys(Regions);
            } else if (Array.isArray(context.region)) {
                r = context.region;
            }
            r.forEach((s) => {
                options.push(
                    <MenuItem key={s} value={s}>
                        <ListItemText primary={Regions[s]} />
                    </MenuItem>,
                );
            });
            setRegionFilterOptions(options);
        }
    }, [context]);

    const updateFromDate = (v: moment.Moment | null) => {
        if (v) {
            if (v.isAfter(toDate)) {
                snackbarUtils.warning(
                    'Beginndatum kann nicht nach Enddatum liegen',
                );
                return;
            }
            setFromDate(v);
        }
    };

    const updateToDate = (v: moment.Moment | null) => {
        if (v) {
            if (v.isBefore(fromDate)) {
                snackbarUtils.warning(
                    'Enddatum kann nicht vor Beginndatum liegen',
                );
                return;
            }
            setToDate(v);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box>
                <Box
                    display="flex"
                    sx={{
                        p: 1,
                        width: '70%',
                        flexDirection: { xs: 'column', sm: 'row' },
                    }}
                >
                    <Box sx={{ width: '22rem', flex: 1, mr: { sm: 5 } }}>
                        <DatePicker
                            label="Erstelldatum ab"
                            onChange={(v) => updateFromDate(v)}
                            inputFormat="DD.MM.YYYY"
                            value={fromDate}
                            disabled={showAll}
                            renderInput={(params) => (
                                <TextField fullWidth {...params} />
                            )}
                        />
                    </Box>
                    <Box
                        sx={{
                            width: '22rem',
                            flex: 1,
                            mr: { sm: 5 },
                            mt: { xs: 2, sm: 0 },
                        }}
                    >
                        <DatePicker
                            label="Erstelldatum bis"
                            onChange={(v) => updateToDate(v)}
                            inputFormat="DD.MM.YYYY"
                            value={toDate}
                            disabled={showAll}
                            renderInput={(params) => (
                                <TextField fullWidth {...params} />
                            )}
                        />
                    </Box>
                    <Box sx={{ width: '22rem', flex: 1 }}>
                        <FormControlLabel
                            sx={{ width: '13rem' }}
                            control={
                                <Checkbox
                                    value={showAll}
                                    checked={showAll}
                                    onChange={(e) => {
                                        setShowAll(e.target.checked);
                                        if (showMy) setShowMy(false);
                                        if (e.target.checked) setUserFilter([]);
                                    }}
                                />
                            }
                            label="Alle Verträge anzeigen"
                        />
                    </Box>
                    {context.role && context.role !== Role.USER && (
                        <Box sx={{ width: '22rem', flex: 1 }}>
                            <FormControlLabel
                                sx={{ width: '15rem' }}
                                control={
                                    <Checkbox
                                        value={showMy}
                                        checked={showMy}
                                        onChange={(e) => {
                                            setShowMy(e.target.checked);
                                            if (showAll) setShowAll(false);
                                            if (e.target.checked)
                                                setUserFilter([]);
                                        }}
                                    />
                                }
                                label="Nur meine Verträge anzeigen"
                            />
                        </Box>
                    )}
                </Box>
                <Box
                    display="flex"
                    sx={{
                        p: 1,
                        pt: { xs: 0, sm: 1 },
                        width: '30%',
                        flexDirection: { xs: 'column', sm: 'row' },
                        flexWrap: 'initial',
                    }}
                >
                    <Box
                        sx={{
                            width: '22rem',
                            flex: 1,
                            mr: { sm: 5 },
                            mt: { xs: 2 },
                        }}
                    >
                        <FormControl sx={{ width: '22rem' }}>
                            <InputLabel>Status</InputLabel>
                            <Select
                                value={statusFilter}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setStatusFilter(
                                        typeof value === 'string'
                                            ? value.split(',')
                                            : value,
                                    );
                                }}
                                input={<OutlinedInput label="Status" />}
                                renderValue={(selected) =>
                                    selected
                                        .map((s) => ContractStatus[s])
                                        .join(', ')
                                }
                                multiple
                            >
                                {Object.keys(ContractStatus).map((s) => {
                                    return (
                                        <MenuItem key={s} value={s}>
                                            <Checkbox
                                                checked={
                                                    statusFilter.indexOf(s) > -1
                                                }
                                            />
                                            <ListItemText
                                                primary={ContractStatus[s]}
                                            />
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                    {context.isAdmin && (
                        <Box
                            sx={{
                                width: '22rem',
                                flex: 1,
                                mr: { sm: 5 },
                                mt: { xs: 2 },
                            }}
                        >
                            <FormControl sx={{ width: '22rem' }}>
                                <InputLabel>Region</InputLabel>
                                <Select
                                    value={regionFilter}
                                    label="Region"
                                    onChange={(e) =>
                                        setRegionFilter(e.target.value)
                                    }
                                >
                                    {regionFilterOptions}
                                </Select>
                            </FormControl>
                        </Box>
                    )}
                    <Box
                        sx={{
                            width: '22rem',
                            flex: 1,
                            mr: { sm: 5 },
                            mt: { xs: 2 },
                        }}
                    >
                        <TextField
                            fullWidth
                            label="Suche"
                            sx={{ width: '22rem' }}
                            value={transientSearchText}
                            onChange={(e) => {
                                const value = e.currentTarget.value;
                                setTransientSearchText(value);
                                if (value === '') setSearchText(value);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setSearchText(transientSearchText);
                                }
                            }}
                            InputProps={{
                                endAdornment:
                                    transientSearchText.length > 0 ? (
                                        <InputAdornment position="end">
                                            <Tooltip title="Suche verwerfen">
                                                <IconButton
                                                    onClick={() => {
                                                        setTransientSearchText(
                                                            '',
                                                        );
                                                        setSearchText('');
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faX}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Suchen">
                                                <IconButton
                                                    onClick={() =>
                                                        setSearchText(
                                                            transientSearchText,
                                                        )
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faSearch}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ) : null,
                            }}
                        />
                    </Box>
                    {context.role && context.role !== Role.USER && (
                        <Box
                            sx={{
                                width: '22rem',
                                flex: 1,
                                mr: { sm: 5 },
                                mt: { xs: 2 },
                            }}
                        >
                            <UserSelection
                                userFilter={userFilter}
                                includeInactive={includeInactiveUsers}
                                setUserFilter={(value) => {
                                    setUserFilter(value);
                                    if (value.length > 0) {
                                        if (showAll) setShowAll(false);
                                        if (showMy) setShowMy(false);
                                    }
                                }}
                            />
                        </Box>
                    )}
                </Box>
                <Box
                    display="flex"
                    sx={{
                        p: 1,
                        pt: { xs: 0, sm: 1 },
                        width: '30%',
                        flexDirection: { xs: 'column', sm: 'row' },
                        flexWrap: 'initial',
                    }}
                >
                    {context.isAdmin && (
                        <Box sx={{ width: '22rem', flex: 1 }}>
                            <FormControlLabel
                                sx={{ width: '15rem' }}
                                control={
                                    <Checkbox
                                        value={includeInactiveUsers}
                                        checked={includeInactiveUsers}
                                        onChange={(e) => {
                                            setIncludeInactiveUsers(
                                                e.target.checked,
                                            );
                                        }}
                                    />
                                }
                                label="Inaktive Nutzer anzeigen"
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </LocalizationProvider>
    );
};

export default FilterToolbar;
