import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { FC, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { LeaderboardApi } from '../../api';
import { AppContext } from '../../hooks/context';

interface LeaderboardProps {
    current: boolean;
}

const Leaderboard: FC<LeaderboardProps> = (props) => {
    const context = useContext(AppContext);
    const [totalRowCount, setTotalRowCount] = useState(10);
    const [rows, setRows] = useState([]);

    const { isLoading, error, data } = useQuery({
        queryKey: ['getUsers', props.current],
        queryFn: () =>
            props.current
                ? LeaderboardApi.getCurrentLeaderboard(context.authToken)
                : LeaderboardApi.getLastMonthLeaderboard(context.authToken),
        enabled: context.authToken !== undefined && context.isAdmin,
    });

    useEffect(() => {
        if (error) {
            console.error(error);
        } else if (data && data.data) {
            setRows(data.data);
            setTotalRowCount(data.data.length);
        }
    }, [data, error]);

    const columns: GridColDef[] = [
        {
            field: 'place',
            headerName: 'Platz',
            editable: false,
            flex: 1,
        },
        {
            field: 'name',
            headerName: 'Name',
            editable: false,
            sortable: false,
            flex: 1,
        },
        {
            field: 'contracts',
            headerName: !props.current
                ? 'Alle aktivierten Verträge'
                : 'Alle Verträge',
            editable: false,
            sortable: false,
            flex: 1,
        },
        {
            field: 'landline',
            headerName: !props.current
                ? 'Aktiviert Festnetz'
                : 'Festnetz Verträge',
            editable: false,
            sortable: false,
            flex: 1,
        },
        {
            field: 'mobile',
            headerName: !props.current ? 'Aktiviert Mobilfunk' : 'Mobilfunk',
            editable: false,
            sortable: false,
            flex: 1,
        },
        {
            field: 'congstar',
            headerName: !props.current ? 'Aktiviert Congstar' : 'Congstar',
            editable: false,
            sortable: false,
            flex: 1,
        },
    ];

    return (
        <DataGrid
            columns={columns}
            rows={rows}
            loading={isLoading}
            getRowId={(row) => row.id}
            pageSize={15}
            rowsPerPageOptions={[15]}
            rowCount={totalRowCount}
            autoHeight
            experimentalFeatures={{ newEditingApi: true }}
            disableColumnSelector
            disableColumnFilter
            disableColumnMenu
        />
    );
};

export default Leaderboard;
