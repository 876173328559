import { Paper, Box, Chip, Typography, Modal, IconButton } from '@mui/material';
import { FC, useState } from 'react';
import { parseDate } from '../../utils/misc-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

interface NewsProps {
    startDate?: Date;
    endDate?: Date;
    text: string;
    title: string;
    imageUrl?: string;
}

const News: FC<NewsProps> = (props) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Paper sx={{ m: 2, pt: 1, pl: 1, pb: 1 }}>
                <Box
                    sx={{
                        maxHeight: 350,
                        display: 'flex',
                        alignContent: 'center',
                        flexDirection: { xs: 'column', md: 'row' },
                        justifyContent: 'flex-start',
                        pt: 1,
                        pb: 1,
                    }}
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        sx={{ width: 400 }}
                    >
                        <span style={{ width: 400 }}>
                            {props.imageUrl && (
                                <Box
                                    maxHeight={
                                        props.endDate || props.startDate ? 200 : 250
                                    }
                                    maxWidth={400}
                                    component="img"
                                    src={props.imageUrl}
                                    onClick={handleOpen}
                                    sx={{ cursor: 'pointer' }}
                                />
                            )}
                        </span>
                    </Box>
                    <Box
                        sx={{ overflowY: 'auto', mr: 0, ml: 1 }}
                        textAlign="justify"
                    >
                        {props.endDate && props.startDate && (
                            <Box sx={{ mt: 1, mb: 1 }} alignSelf="baseline">
                                <Chip
                                    variant="outlined"
                                    label={
                                        parseDate(props.startDate) +
                                        ' - ' +
                                        parseDate(props.endDate)
                                    }
                                    sx={{ fontWeight: 800 }}
                                />
                            </Box>
                        )}
                        {props.endDate && !props.startDate && (
                            <Box sx={{ mt: 1, mb: 1 }} alignSelf="baseline">
                                <Chip
                                    variant="outlined"
                                    label={'bis ' + parseDate(props.endDate)}
                                    sx={{ fontWeight: 800 }}
                                />
                            </Box>
                        )}
                        {!props.endDate && props.startDate && (
                            <Box sx={{ mt: 1, mb: 1 }} alignSelf="baseline">
                                <Chip
                                    variant="outlined"
                                    label={'ab ' + parseDate(props.startDate)}
                                    sx={{ fontWeight: 800 }}
                                />
                            </Box>
                        )}
                        <Typography variant="h6">{props.title}</Typography>
                        <Typography sx={{ pr: 2 }}>{props.text}</Typography>
                    </Box>
                </Box>
            </Paper>

            {/* Modal for Full-Screen Image */}
            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        zIndex: 1300,
                        p: 2,
                    }}
                >
                    <IconButton
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            top: 16,
                            right: 16,
                            color: 'white',
                        }}
                    >
                        <FontAwesomeIcon icon={faX} />
                    </IconButton>
                    <Box
                        component="img"
                        src={props.imageUrl}
                        sx={{ maxHeight: '70%', maxWidth: '60%', mb: 2 }}
                    />
                    <Typography
                        sx={{ color: 'white', textAlign: 'center', maxWidth: '90%', fontSize: '20px' }}
                    >
                        {props.text}
                    </Typography>
                </Box>
            </Modal>
        </>
    );
};

export default News;
