import { Box, Button, ButtonGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { Leaderboard, Navbar } from '../../components';
import { setDocumentTitle, loadTitle } from '../../utils/misc-utils';

const Leaderboards = () => {
    const [current, setCurrent] = useState(true);

    useEffect(() => {
        setDocumentTitle('Leaderboard');
        return () => loadTitle();
    }, []);

    return (
        <Box>
            <Navbar title="Leaderboard" />
            <Box display="flex" justifyContent="center" mb={2}>
                <ButtonGroup>
                    <Button
                        onClick={() => setCurrent(true)}
                        variant={current ? 'contained' : 'outlined'}
                    >
                        Aktueller Monat
                    </Button>
                    <Button
                        onClick={() => setCurrent(false)}
                        variant={current ? 'outlined' : 'contained'}
                    >
                        Letzter Monat
                    </Button>
                </ButtonGroup>
            </Box>
            <Box sx={{ height: '100%', mr: 5, ml: 5 }}>
                <Leaderboard current={current} />
            </Box>
        </Box>
    );
};

export default Leaderboards;
