import {
    AppBar,
    Box,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';
import { FC, useContext, useState } from 'react';
import { AppContext } from '../../hooks/context';
import { Header } from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBars,
    faArrowRightFromBracket,
    faUpload,
    faFileContract,
    // faUsers,
    // faGraduationCap,
    faHouseUser,
    faAward,
    faLock,
    faTrophy,
    // faBriefcase
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import snackbarUtils from '../../utils/snackbar/snackbar-utils';

interface NavbarProps {
    title?: string;
    subTitle?: string;
    addClient?: boolean;
    search?: boolean;
    noMb?: boolean;
}

const defaultProps = {
    search: false,
};

const Navbar: FC<NavbarProps> = (props) => {
    const context = useContext(AppContext);
    const navigate = useNavigate();
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchor);
    // const [isDataComplete, setDataComplete] = useState<boolean>(false);

    const performSignout = async () => {
        try {
            context.signout();
            snackbarUtils.success('Erfolgreich ausgeloggt');
        } catch (error) {
            console.error(error);
            snackbarUtils.error(
                'Da ist leider etwas schief gelaufen. Bitte versuchen Sie es später erneut.',
            );
        }
    };

    // useEffect(() => {
    //     if (context.authToken) {
    //         const getIsDataComplete = async () => {
    //             try {
    //                 const res = await UserApi.isDataComplete(context.authToken);
    //                 if (res) {
    //                     setDataComplete(res.data.isComplete);
    //                 }
    //             } catch (e) {}
    //         };
    //         getIsDataComplete();
    //     }
    // }, [context.authToken]);

    const menuItems = [
        {
            label: 'Dashboard',
            action: () => navigate('/dashboard'),
            pathname: '/dashboard',
            icon: faHouseUser,
        },
        // {
        //     label: 'Mein Bereich',
        //     action: () => navigate('/user-space'),
        //     icon: faUser,
        //     pathname: '/user-space',
        //     adminOnly: false,
        //     incompleteBadge: true,
        // },
        // {
        //     label: 'Backoffice',
        //     action: () => navigate('/backoffice'),
        //     pathname: '/backoffice',
        //     icon: faBriefcase
        // },
        {
            label: 'Vertrag hochladen',
            action: () => navigate('/upload'),
            icon: faUpload,
            pathname: '/upload',
            disabled: false,
        },
        // {
        //     label: 'Punktespiel',
        //     action: () => navigate('/prize-wheel'),
        //     icon: faAward,
        //     pathname: '/prize-wheel',
        //     disabled: false,
        // },
        {
            label: 'Leaderboard',
            action: () => navigate('/leaderboard'),
            icon: faTrophy,
            pathname: '/leaderboard',
        },
        {
            label: 'Verträge',
            action: () => navigate('/contracts'),
            icon: faFileContract,
            pathname: '/contracts',
            adminOnly: false,
        },
        // {
        //     label: 'Sales Academy',
        //     action: () => navigate('/academy'),
        //     pathname: '/academy',
        //     icon: faGraduationCap
        // },
        // {
        //     label: 'Benutzerverwaltung',
        //     action: () => navigate('/admin/users'),
        //     pathname: '/admin/users',
        //     icon: faUsers,
        //     adminOnly: true,
        // },
        {
            label: 'Logout',
            action: () => performSignout(),
            icon: faArrowRightFromBracket,
        },
    ];

    return (
        <AppBar
            position="static"
            color="transparent"
            sx={{ mb: props.noMb ? 0 : 2 }}
        >
            <Toolbar>
                <Box
                    sx={{
                        flexGrow: 1,
                        alignItems: 'center',
                        textTransform: 'uppercase',
                        cursor: 'pointer',
                    }}
                    onClick={() => navigate('/upload')}
                >
                    <Header title={props.title} subTitle={props.subTitle} />
                </Box>
                <Box flexGrow="1"></Box>
                {context.isAuthenticated ? (
                    <Box>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: 'none', md: 'flex' },
                            }}
                        >
                            {menuItems.map((item) => {
                                if (item.adminOnly && !context.isAdmin)
                                    return <span key={item.label} />;
                                return (
                                    <>
                                        <MenuItem
                                            key={item.label}
                                            onClick={
                                                item.disabled
                                                    ? () => {}
                                                    : item.action
                                            }
                                        >
                                            {item.icon && (
                                                <ListItemIcon
                                                    sx={
                                                        item.disabled
                                                            ? { opacity: 0.38 }
                                                            : {}
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={item.icon}
                                                    />
                                                </ListItemIcon>
                                            )}
                                            <ListItemText
                                                sx={
                                                    item.disabled
                                                        ? { opacity: 0.38 }
                                                        : {}
                                                }
                                            >
                                                <Typography
                                                    fontWeight={
                                                        item.pathname &&
                                                        window.location
                                                            .pathname ===
                                                            item.pathname
                                                            ? 'bold'
                                                            : 'regular'
                                                    }
                                                >
                                                    {item.label}
                                                </Typography>
                                            </ListItemText>
                                            {item.disabled && (
                                                <Tooltip title="Bald verfügbar">
                                                    <ListItemIcon
                                                        sx={{ ml: 1 }}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faLock}
                                                        />
                                                    </ListItemIcon>
                                                </Tooltip>
                                            )}
                                            {/* </Badge> */}
                                        </MenuItem>
                                    </>
                                );
                            })}
                        </Box>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: 'flex', md: 'none' },
                            }}
                        >
                            <IconButton
                                size="large"
                                color="inherit"
                                aria-label="menu"
                                onClick={(event) =>
                                    setMenuAnchor(event.currentTarget)
                                }
                            >
                                <FontAwesomeIcon icon={faBars} />
                            </IconButton>
                        </Box>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: 'flex', md: 'none' },
                            }}
                        >
                            <Menu
                                id="menu-appbar"
                                anchorEl={menuAnchor}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={menuOpen}
                                onClose={() => setMenuAnchor(null)}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {menuItems.map((item) => {
                                    if (item.adminOnly && !context.isAdmin)
                                        return <span key={item.label} />;
                                    return (
                                        <MenuItem
                                            key={item.label}
                                            onClick={
                                                item.disabled
                                                    ? () => {}
                                                    : item.action
                                            }
                                        >
                                            {/* <Badge
                                                color="warning"
                                                variant="dot"
                                                invisible={
                                                    !item.incompleteBadge ||
                                                    isDataComplete
                                                }
                                            > */}
                                            {item.icon && (
                                                <ListItemIcon
                                                    sx={
                                                        item.disabled
                                                            ? { opacity: 0.38 }
                                                            : {}
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={item.icon}
                                                    />
                                                </ListItemIcon>
                                            )}
                                            <ListItemText
                                                sx={
                                                    item.disabled
                                                        ? { opacity: 0.38 }
                                                        : {}
                                                }
                                            >
                                                {item.label}
                                            </ListItemText>
                                            {/* </Badge> */}
                                            {item.disabled && (
                                                <Tooltip title="Bald verfügbar">
                                                    <ListItemIcon
                                                        sx={{ ml: 1 }}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faLock}
                                                        />
                                                    </ListItemIcon>
                                                </Tooltip>
                                            )}
                                        </MenuItem>
                                    );
                                })}
                            </Menu>
                        </Box>
                    </Box>
                ) : null}
            </Toolbar>
        </AppBar>
    );
};

Navbar.defaultProps = defaultProps;

export default Navbar;
