import { ContractStatus, ContractType } from '../../types/contract';
import { Field, FieldType } from '../../types/edit-contract';
import { Regions } from '../../types/region';

export const LandlineLeftInputFields: Field[] = [
    {
        key: 'combinedName',
        label: 'Name',
        type: FieldType.TEXT,
        hideIfUndefined: true,
    },
    {
        key: 'salutation',
        label: 'Anrede',
        type: FieldType.SELECT,
        values: ['Frau', 'Herr', 'Firma'],
    },
    {
        key: 'firstname',
        label: 'Vorname',
        type: FieldType.TEXT,
    },
    {
        key: 'lastname',
        label: 'Nachname',
        type: FieldType.TEXT,
    },
    {
        key: 'street',
        label: 'Straße, Hausnummer',
        type: FieldType.TEXT,
        hideNonAdmin: true,
    },
    {
        key: 'zip',
        label: 'Postleitzahl',
        type: FieldType.TEXT,
        hideNonAdmin: true,
    },
    {
        key: 'city',
        label: 'Ort',
        type: FieldType.TEXT,
        hideNonAdmin: true,
    },
    {
        key: 'phone',
        label: 'Telefonnummer',
        type: FieldType.TEXT,
        hideNonAdmin: true,
    },
    {
        key: 'callbackPhone',
        label: 'Rückrufnummer',
        type: FieldType.TEXT,
        hideNonAdmin: true,
    },
    {
        key: 'birthday',
        label: 'Geburtsdatum',
        type: FieldType.TEXT,
        hideNonAdmin: true,
    },
    {
        key: 'customerNumber',
        label: 'Kundennummer',
        type: FieldType.TEXT,
        hideNonAdmin: true,
    },
    {
        key: 'email',
        label: 'E-Mail',
        type: FieldType.TEXT,
        hideNonAdmin: true,
    },
];

export const LandlineRightInputFields: Field[] = [
    {
        key: 'type',
        label: 'Vertragsart',
        type: FieldType.SELECT,
        valueType: ContractType,
    },
    {
        key: 'orderNumber',
        label: 'TVPP - Auftragsnummer (Bestellnummer)',
        type: FieldType.TEXT,
    },
    {
        key: 'contractNumber',
        label: 'CRMT - Auftragsnummer (Vertragsnummer)',
        emptyIfRemoteValStartsWith: 'CRMT-EMPTY-',
        editableIfRemoteValStartsWith: 'CRMT-EMPTY-',
        type: FieldType.TEXT,
    },
    {
        key: 'bkto',
        label: 'BKTO',
        type: FieldType.TEXT,
        hideNonAdmin: true,
    },
    {
        key: 'region',
        label: 'Region',
        type: FieldType.SELECT,
        valueType: Regions,
        hideNonAdmin: true,
    },
    {
        key: 'orderDate',
        label: 'Bestelldatum',
        type: FieldType.TEXT,
    },
    {
        key: 'executionDate',
        label: 'Ausführungsdatum',
        type: FieldType.TEXT,
    },
    {
        key: 'creator',
        label: 'Verkäufer',
        type: FieldType.USER_SELECT,
        notEditable: true,
    },
    {
        key: 'customerContractEmailSent',
        label: 'E-Mail an Kunden verschickt',
        type: FieldType.TEXT,
        hideNonAdmin: true,
        notEditable: true,
        valueFormatter: (v: boolean) => (v ? 'JA' : 'NEIN'),
    },
    {
        key: 'status',
        label: 'Status',
        type: FieldType.SELECT,
        valueType: ContractStatus,
    },
];
